/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { HeaderNavBarLogo1 } from "../../icons/HeaderNavBarLogo1";
import { MiscIconsSocial } from "../../icons/MiscIconsSocial";
import { MiscIconsSocial1 } from "../../icons/MiscIconsSocial1";
import { MiscIconsSocial2 } from "../../icons/MiscIconsSocial2";
import { MiscIconsSocial3 } from "../../icons/MiscIconsSocial3";
import { MiscIconsSocial4 } from "../../icons/MiscIconsSocial4";
import { MiscIconsSocialInstagramOriginalDefault } from "../../icons/MiscIconsSocialInstagramOriginalDefault";
import "./style.css";
import { Link } from "react-router-dom";

export const FooterSection = ({
  text = true,
  type,
  className,
  copyrightClassName,
  text1 = "© 2022 Stratis UI - All rights reserved",
  subheadTitleClassName,
  subheadTitleClassNameOverride,
  divClassName,
}) => {
  return (
    <div className={`footer-section type-${type} ${className}`}>
      {["subscribe-stacked", "subscribe"].includes(type) && (
        <>
          <div className="item-text-input">
            <div className="frame">
              <div className="container">
                <div className="texthead-title">Email address</div>
              </div>
            </div>
          </div>
          <button className="buttons-primary">
            <button className="button-text">Sign up</button>
          </button>
        </>
      )}

      {["basic-nav-vertical", "basic-nav"].includes(type) && (
        <>
          <div className="footer-section-link">
            <div className="link">Home</div>
          </div>
          <div className="link-wrapper">
            <div className="link">Pricing</div>
          </div>
          <div className="div-wrapper">
            <div className="link">Contact</div>
          </div>
          <div className="div">
            <div className="link">Careers</div>
          </div>
          <div className="footer-section-link-2">
            <div className="link">Support</div>
          </div>
          <div className="footer-section-link-3">
            <div className="link">Privacy</div>
          </div>
        </>
      )}

      {["copyright", "footer-section"].includes(type) && (
        <Link to={'/terms'}
          className={`subhead-title ${
            type === "footer-section" ? subheadTitleClassName : type === "copyright" ? copyrightClassName : undefined
          }`}
        >
          {type === "footer-section" && <>Terms &amp; Conditions</>}

          {type === "copyright" && <p className="text-wrapper-13">{text1}</p>}
        </Link>
      )}

      {type === "socials" && (
        <>
          <MiscIconsSocial className="misc-icons-social" />
          <MiscIconsSocial1 className="misc-icons-social" />
          <MiscIconsSocial2 className="misc-icons-social" />
          <MiscIconsSocialInstagramOriginalDefault className="misc-icons-social" />
          <MiscIconsSocial3 className="misc-icons-social" />
          <MiscIconsSocial4 className="misc-icons-social" />
        </>
      )}

      {type === "footer-section" && (
        <>
          {/* <div className={`text-wrapper ${subheadTitleClassNameOverride}`}>Cookies</div> */}
          <Link to={'/privacy'} className={`text-wrapper ${divClassName}`}>Privacy Policy</Link>
        </>
      )}

      {type === "logo" && <HeaderNavBarLogo1 className="header-nav-bar-logo" />}

      {["logo", "text"].includes(type) && (
        <>
          <>
            {text && (
              <p className={`p ${type === "text" ? copyrightClassName : undefined}`}>
                You are just one step away from transforming your digital design crafting experience with Stratis UI Kit
              </p>
            )}
          </>
        </>
      )}
    </div>
  );
};

FooterSection.propTypes = {
  text: PropTypes.bool,
  type: PropTypes.oneOf([
    "copyright",
    "footer-section",
    "basic-nav",
    "socials",
    "subscribe-stacked",
    "basic-nav-vertical",
    "text",
    "subscribe",
    "logo",
  ]),
  text1: PropTypes.string,
};
