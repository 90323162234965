/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const HeaderNavBarLogo1 = ({ className }) => {
  return (
    <svg
      className={`header-nav-bar-logo-1 ${className}`}
      fill="none"
      height="38"
      viewBox="0 0 116 38"
      width="116"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" filter="url(#filter0_d_294_5058)">
        <rect
          className="rect"
          height="32.5"
          rx="4.25"
          shapeRendering="crispEdges"
          stroke="#E6E6E6"
          strokeWidth="0.5"
          width="32.5"
          x="2.75"
          y="1.75"
        />
        <g className="g" filter="url(#filter1_i_294_5058)">
          <rect className="rect" fill="url(#paint0_linear_294_5058)" height="32" rx="4" width="32" x="3" y="2" />
        </g>
        <path
          className="path"
          d="M3 8.39985H35M3 14.8H35M3 21.1999H35M3 27.6H35M28.6001 2L28.6001 34M22.2 2L22.2 34M15.8001 2L15.8 34M9.3999 2L9.3999 34"
          stroke="#E6E6E6"
          strokeWidth="0.2"
        />
        <path
          className="path"
          d="M18.7006 15.2778C19.0058 15.1054 19.379 15.1054 19.6842 15.2778L27.614 19.757C27.9528 19.9484 27.9528 20.4364 27.614 20.6277L19.6842 25.107C19.379 25.2794 19.0058 25.2794 18.7006 25.107L10.7707 20.6277C10.4319 20.4364 10.4319 19.9484 10.7707 19.757L18.7006 15.2778Z"
          fill="#113B98"
        />
        <path
          className="path"
          d="M18.7006 12.2778C19.0058 12.1054 19.379 12.1054 19.6842 12.2778L27.614 16.757C27.9528 16.9484 27.9528 17.4364 27.614 17.6277L19.6842 22.107C19.379 22.2794 19.0058 22.2794 18.7006 22.107L10.7707 17.6277C10.4319 17.4364 10.4319 16.9484 10.7707 16.757L18.7006 12.2778Z"
          fill="#1751D0"
        />
        <path
          className="path"
          d="M18.7006 9.27781C19.0058 9.1054 19.379 9.1054 19.6842 9.27781L27.614 13.757C27.9528 13.9484 27.9528 14.4364 27.614 14.6277L19.6842 19.107C19.379 19.2794 19.0058 19.2794 18.7006 19.107L10.7707 14.6277C10.4319 14.4364 10.4319 13.9484 10.7707 13.757L18.7006 9.27781Z"
          fill="#1751D0"
        />
        <circle className="circle" cx="19" cy="18" r="12.9" stroke="#C9C9CC" strokeWidth="0.2" />
      </g>
      <mask
        className="mask"
        fill="black"
        height="22"
        id="path-9-outside-1_294_5058"
        maskUnits="userSpaceOnUse"
        width="74"
        x="42"
        y="7"
      >
        <rect className="rect" fill="white" height="22" width="74" x="42" y="7" />
        <path
          className="path"
          d="M54.2477 13.3631C54.1359 12.4196 53.6828 11.6872 52.8883 11.1657C52.0937 10.6443 51.1192 10.3836 49.9646 10.3836C49.1204 10.3836 48.3818 10.5202 47.7486 10.7933C47.1217 11.0664 46.6313 11.442 46.2775 11.9199C45.9299 12.3979 45.7561 12.941 45.7561 13.5493C45.7561 14.0583 45.8771 14.496 46.1192 14.8622C46.3675 15.2222 46.684 15.5233 47.0689 15.7654C47.4538 16.0012 47.8572 16.1968 48.2793 16.352C48.7014 16.5009 49.0894 16.622 49.4432 16.7151L51.3799 17.2365C51.8765 17.3669 52.4289 17.5469 53.0372 17.7765C53.6518 18.0062 54.2384 18.3197 54.797 18.7169C55.3619 19.108 55.8274 19.6108 56.1937 20.2253C56.5599 20.8399 56.743 21.594 56.743 22.4879C56.743 23.5183 56.473 24.4494 55.933 25.2812C55.3991 26.113 54.617 26.7741 53.5866 27.2644C52.5624 27.7548 51.3178 28 49.8529 28C48.4873 28 47.3048 27.7796 46.3054 27.3389C45.3122 26.8982 44.5301 26.2837 43.959 25.4953C43.3942 24.707 43.0745 23.7914 43 22.7486H45.3836C45.4457 23.4687 45.6878 24.0646 46.1099 24.5363C46.5382 25.0019 47.0782 25.3495 47.73 25.5791C48.388 25.8026 49.0956 25.9143 49.8529 25.9143C50.7343 25.9143 51.5258 25.7716 52.2272 25.486C52.9286 25.1943 53.4842 24.7908 53.8939 24.2756C54.3035 23.7542 54.5084 23.1459 54.5084 22.4507C54.5084 21.8175 54.3315 21.3023 53.9777 20.905C53.6238 20.5078 53.1583 20.185 52.581 19.9367C52.0037 19.6884 51.3799 19.4711 50.7095 19.2849L48.3631 18.6145C46.8734 18.1862 45.694 17.5748 44.825 16.7803C43.9559 15.9857 43.5214 14.946 43.5214 13.6611C43.5214 12.5934 43.8101 11.6623 44.3873 10.8678C44.9708 10.067 45.753 9.44631 46.7337 9.00559C47.7207 8.55866 48.8225 8.33519 50.0391 8.33519C51.2682 8.33519 52.3607 8.55555 53.3166 8.99627C54.2725 9.43079 55.0298 10.0267 55.5885 10.784C56.1533 11.5413 56.4513 12.401 56.4823 13.3631H54.2477Z"
        />
        <path
          className="path"
          d="M65.548 13.3631V15.2253H58.1364V13.3631H65.548ZM60.2966 9.93669H62.4939V23.568C62.4939 24.1887 62.584 24.6543 62.764 24.9646C62.9502 25.2688 63.1861 25.4736 63.4716 25.5791C63.7633 25.6785 64.0706 25.7281 64.3934 25.7281C64.6355 25.7281 64.8341 25.7157 64.9893 25.6909C65.1445 25.6598 65.2686 25.635 65.3617 25.6164L65.8087 27.5903C65.6597 27.6462 65.4517 27.702 65.1848 27.7579C64.9179 27.82 64.5796 27.851 64.1699 27.851C63.5492 27.851 62.9409 27.7176 62.345 27.4507C61.7553 27.1837 61.2649 26.7772 60.8738 26.2309C60.489 25.6847 60.2966 24.9957 60.2966 24.1639V9.93669Z"
        />
        <path
          className="path"
          d="M67.9399 27.6648V13.3631H70.0628V15.5233H70.2118C70.4725 14.8156 70.9443 14.2415 71.6271 13.8007C72.3099 13.36 73.0796 13.1397 73.9362 13.1397C74.0976 13.1397 74.2993 13.1428 74.5414 13.149C74.7835 13.1552 74.9666 13.1645 75.0908 13.1769V15.4115C75.0163 15.3929 74.8456 15.365 74.5787 15.3277C74.318 15.2843 74.0417 15.2626 73.75 15.2626C73.0548 15.2626 72.434 15.4084 71.8878 15.7002C71.3478 15.9857 70.9195 16.383 70.6029 16.892C70.2925 17.3948 70.1373 17.969 70.1373 18.6145V27.6648H67.9399Z"
        />
        <path
          className="path"
          d="M80.9907 28C80.0844 28 79.2619 27.8293 78.5233 27.4879C77.7846 27.1403 77.198 26.6406 76.7635 25.9888C76.329 25.3309 76.1117 24.5363 76.1117 23.6052C76.1117 22.7858 76.2731 22.1217 76.5959 21.6127C76.9187 21.0975 77.3501 20.694 77.8901 20.4022C78.4302 20.1105 79.0261 19.8932 79.6778 19.7505C80.3358 19.6015 80.9969 19.4836 81.6611 19.3966C82.5301 19.2849 83.2346 19.2011 83.7747 19.1453C84.3209 19.0832 84.7182 18.9808 84.9665 18.838C85.221 18.6952 85.3482 18.4469 85.3482 18.0931V18.0186C85.3482 17.0999 85.0968 16.3861 84.594 15.8771C84.0975 15.3681 83.3433 15.1136 82.3315 15.1136C81.2824 15.1136 80.46 15.3433 79.8641 15.8026C79.2681 16.2619 78.8492 16.7523 78.6071 17.2737L76.5214 16.5289C76.8938 15.6598 77.3904 14.9832 78.0112 14.4991C78.6381 14.0087 79.3209 13.6673 80.0596 13.4749C80.8045 13.2762 81.5369 13.1769 82.257 13.1769C82.7163 13.1769 83.2439 13.2328 83.8398 13.3445C84.442 13.45 85.0223 13.6704 85.581 14.0056C86.1459 14.3408 86.6145 14.8467 86.987 15.5233C87.3594 16.1999 87.5456 17.1061 87.5456 18.2421V27.6648H85.3482V25.7281H85.2365C85.0875 26.0385 84.8392 26.3706 84.4916 26.7244C84.144 27.0782 83.6816 27.3793 83.1043 27.6276C82.527 27.8759 81.8225 28 80.9907 28ZM81.3259 26.0261C82.1949 26.0261 82.9274 25.8554 83.5233 25.514C84.1254 25.1726 84.5785 24.7318 84.8827 24.1918C85.193 23.6518 85.3482 23.0838 85.3482 22.4879V20.4767C85.2551 20.5885 85.0503 20.6909 84.7337 20.784C84.4233 20.8709 84.0633 20.9485 83.6536 21.0168C83.2501 21.0788 82.856 21.1347 82.4711 21.1844C82.0925 21.2278 81.7852 21.2651 81.5493 21.2961C80.9783 21.3706 80.4444 21.4916 79.9478 21.6592C79.4575 21.8206 79.0602 22.0658 78.756 22.3948C78.4581 22.7176 78.3091 23.1583 78.3091 23.7169C78.3091 24.4804 78.5916 25.0577 79.1564 25.4488C79.7275 25.8336 80.4506 26.0261 81.3259 26.0261Z"
        />
        <path
          className="path"
          d="M96.8627 13.3631V15.2253H89.4511V13.3631H96.8627ZM91.6113 9.93669H93.8086V23.568C93.8086 24.1887 93.8987 24.6543 94.0787 24.9646C94.2649 25.2688 94.5008 25.4736 94.7863 25.5791C95.078 25.6785 95.3853 25.7281 95.7081 25.7281C95.9502 25.7281 96.1488 25.7157 96.304 25.6909C96.4592 25.6598 96.5833 25.635 96.6764 25.6164L97.1234 27.5903C96.9744 27.6462 96.7664 27.702 96.4995 27.7579C96.2326 27.82 95.8943 27.851 95.4846 27.851C94.8639 27.851 94.2556 27.7176 93.6597 27.4507C93.07 27.1837 92.5796 26.7772 92.1885 26.2309C91.8037 25.6847 91.6113 24.9957 91.6113 24.1639V9.93669Z"
        />
        <path
          className="path"
          d="M99.2546 27.6648V13.3631H101.452V27.6648H99.2546ZM100.372 10.9795C99.9437 10.9795 99.5743 10.8336 99.264 10.5419C98.9598 10.2502 98.8077 9.89944 98.8077 9.48976C98.8077 9.08007 98.9598 8.72936 99.264 8.43762C99.5743 8.14587 99.9437 8 100.372 8C100.8 8 101.167 8.14587 101.471 8.43762C101.781 8.72936 101.936 9.08007 101.936 9.48976C101.936 9.89944 101.781 10.2502 101.471 10.5419C101.167 10.8336 100.8 10.9795 100.372 10.9795Z"
        />
        <path
          className="path"
          d="M114.694 16.5661L112.72 17.1248C112.596 16.7958 112.412 16.4761 112.17 16.1657C111.935 15.8492 111.612 15.5885 111.202 15.3836C110.792 15.1788 110.268 15.0763 109.628 15.0763C108.753 15.0763 108.024 15.2781 107.44 15.6816C106.863 16.0788 106.574 16.5847 106.574 17.1993C106.574 17.7455 106.773 18.1769 107.17 18.4935C107.568 18.8101 108.188 19.0739 109.033 19.2849L111.155 19.8063C112.434 20.1167 113.387 20.5916 114.014 21.2309C114.641 21.8641 114.954 22.6803 114.954 23.6797C114.954 24.4991 114.718 25.2315 114.247 25.8771C113.781 26.5227 113.129 27.0317 112.291 27.4041C111.453 27.7765 110.479 27.9628 109.368 27.9628C107.909 27.9628 106.702 27.6462 105.746 27.013C104.79 26.3799 104.185 25.455 103.93 24.2384L106.016 23.7169C106.214 24.4867 106.59 25.0639 107.142 25.4488C107.701 25.8336 108.43 26.0261 109.331 26.0261C110.355 26.0261 111.168 25.8088 111.77 25.3743C112.378 24.9336 112.682 24.406 112.682 23.7914C112.682 23.2948 112.509 22.879 112.161 22.5438C111.813 22.2024 111.28 21.9479 110.56 21.7803L108.176 21.2216C106.866 20.9112 105.904 20.4302 105.29 19.7784C104.681 19.1204 104.377 18.298 104.377 17.311C104.377 16.504 104.604 15.7902 105.057 15.1695C105.516 14.5487 106.14 14.0615 106.928 13.7076C107.723 13.3538 108.623 13.1769 109.628 13.1769C111.044 13.1769 112.155 13.4873 112.962 14.108C113.775 14.7287 114.352 15.5481 114.694 16.5661Z"
        />
      </mask>
      <path
        className="path"
        d="M54.2477 13.3631C54.1359 12.4196 53.6828 11.6872 52.8883 11.1657C52.0937 10.6443 51.1192 10.3836 49.9646 10.3836C49.1204 10.3836 48.3818 10.5202 47.7486 10.7933C47.1217 11.0664 46.6313 11.442 46.2775 11.9199C45.9299 12.3979 45.7561 12.941 45.7561 13.5493C45.7561 14.0583 45.8771 14.496 46.1192 14.8622C46.3675 15.2222 46.684 15.5233 47.0689 15.7654C47.4538 16.0012 47.8572 16.1968 48.2793 16.352C48.7014 16.5009 49.0894 16.622 49.4432 16.7151L51.3799 17.2365C51.8765 17.3669 52.4289 17.5469 53.0372 17.7765C53.6518 18.0062 54.2384 18.3197 54.797 18.7169C55.3619 19.108 55.8274 19.6108 56.1937 20.2253C56.5599 20.8399 56.743 21.594 56.743 22.4879C56.743 23.5183 56.473 24.4494 55.933 25.2812C55.3991 26.113 54.617 26.7741 53.5866 27.2644C52.5624 27.7548 51.3178 28 49.8529 28C48.4873 28 47.3048 27.7796 46.3054 27.3389C45.3122 26.8982 44.5301 26.2837 43.959 25.4953C43.3942 24.707 43.0745 23.7914 43 22.7486H45.3836C45.4457 23.4687 45.6878 24.0646 46.1099 24.5363C46.5382 25.0019 47.0782 25.3495 47.73 25.5791C48.388 25.8026 49.0956 25.9143 49.8529 25.9143C50.7343 25.9143 51.5258 25.7716 52.2272 25.486C52.9286 25.1943 53.4842 24.7908 53.8939 24.2756C54.3035 23.7542 54.5084 23.1459 54.5084 22.4507C54.5084 21.8175 54.3315 21.3023 53.9777 20.905C53.6238 20.5078 53.1583 20.185 52.581 19.9367C52.0037 19.6884 51.3799 19.4711 50.7095 19.2849L48.3631 18.6145C46.8734 18.1862 45.694 17.5748 44.825 16.7803C43.9559 15.9857 43.5214 14.946 43.5214 13.6611C43.5214 12.5934 43.8101 11.6623 44.3873 10.8678C44.9708 10.067 45.753 9.44631 46.7337 9.00559C47.7207 8.55866 48.8225 8.33519 50.0391 8.33519C51.2682 8.33519 52.3607 8.55555 53.3166 8.99627C54.2725 9.43079 55.0298 10.0267 55.5885 10.784C56.1533 11.5413 56.4513 12.401 56.4823 13.3631H54.2477Z"
        fill="#0A090B"
      />
      <path
        className="path"
        d="M65.548 13.3631V15.2253H58.1364V13.3631H65.548ZM60.2966 9.93669H62.4939V23.568C62.4939 24.1887 62.584 24.6543 62.764 24.9646C62.9502 25.2688 63.1861 25.4736 63.4716 25.5791C63.7633 25.6785 64.0706 25.7281 64.3934 25.7281C64.6355 25.7281 64.8341 25.7157 64.9893 25.6909C65.1445 25.6598 65.2686 25.635 65.3617 25.6164L65.8087 27.5903C65.6597 27.6462 65.4517 27.702 65.1848 27.7579C64.9179 27.82 64.5796 27.851 64.1699 27.851C63.5492 27.851 62.9409 27.7176 62.345 27.4507C61.7553 27.1837 61.2649 26.7772 60.8738 26.2309C60.489 25.6847 60.2966 24.9957 60.2966 24.1639V9.93669Z"
        fill="#0A090B"
      />
      <path
        className="path"
        d="M67.9399 27.6648V13.3631H70.0628V15.5233H70.2118C70.4725 14.8156 70.9443 14.2415 71.6271 13.8007C72.3099 13.36 73.0796 13.1397 73.9362 13.1397C74.0976 13.1397 74.2993 13.1428 74.5414 13.149C74.7835 13.1552 74.9666 13.1645 75.0908 13.1769V15.4115C75.0163 15.3929 74.8456 15.365 74.5787 15.3277C74.318 15.2843 74.0417 15.2626 73.75 15.2626C73.0548 15.2626 72.434 15.4084 71.8878 15.7002C71.3478 15.9857 70.9195 16.383 70.6029 16.892C70.2925 17.3948 70.1373 17.969 70.1373 18.6145V27.6648H67.9399Z"
        fill="#0A090B"
      />
      <path
        className="path"
        d="M80.9907 28C80.0844 28 79.2619 27.8293 78.5233 27.4879C77.7846 27.1403 77.198 26.6406 76.7635 25.9888C76.329 25.3309 76.1117 24.5363 76.1117 23.6052C76.1117 22.7858 76.2731 22.1217 76.5959 21.6127C76.9187 21.0975 77.3501 20.694 77.8901 20.4022C78.4302 20.1105 79.0261 19.8932 79.6778 19.7505C80.3358 19.6015 80.9969 19.4836 81.6611 19.3966C82.5301 19.2849 83.2346 19.2011 83.7747 19.1453C84.3209 19.0832 84.7182 18.9808 84.9665 18.838C85.221 18.6952 85.3482 18.4469 85.3482 18.0931V18.0186C85.3482 17.0999 85.0968 16.3861 84.594 15.8771C84.0975 15.3681 83.3433 15.1136 82.3315 15.1136C81.2824 15.1136 80.46 15.3433 79.8641 15.8026C79.2681 16.2619 78.8492 16.7523 78.6071 17.2737L76.5214 16.5289C76.8938 15.6598 77.3904 14.9832 78.0112 14.4991C78.6381 14.0087 79.3209 13.6673 80.0596 13.4749C80.8045 13.2762 81.5369 13.1769 82.257 13.1769C82.7163 13.1769 83.2439 13.2328 83.8398 13.3445C84.442 13.45 85.0223 13.6704 85.581 14.0056C86.1459 14.3408 86.6145 14.8467 86.987 15.5233C87.3594 16.1999 87.5456 17.1061 87.5456 18.2421V27.6648H85.3482V25.7281H85.2365C85.0875 26.0385 84.8392 26.3706 84.4916 26.7244C84.144 27.0782 83.6816 27.3793 83.1043 27.6276C82.527 27.8759 81.8225 28 80.9907 28ZM81.3259 26.0261C82.1949 26.0261 82.9274 25.8554 83.5233 25.514C84.1254 25.1726 84.5785 24.7318 84.8827 24.1918C85.193 23.6518 85.3482 23.0838 85.3482 22.4879V20.4767C85.2551 20.5885 85.0503 20.6909 84.7337 20.784C84.4233 20.8709 84.0633 20.9485 83.6536 21.0168C83.2501 21.0788 82.856 21.1347 82.4711 21.1844C82.0925 21.2278 81.7852 21.2651 81.5493 21.2961C80.9783 21.3706 80.4444 21.4916 79.9478 21.6592C79.4575 21.8206 79.0602 22.0658 78.756 22.3948C78.4581 22.7176 78.3091 23.1583 78.3091 23.7169C78.3091 24.4804 78.5916 25.0577 79.1564 25.4488C79.7275 25.8336 80.4506 26.0261 81.3259 26.0261Z"
        fill="#0A090B"
      />
      <path
        className="path"
        d="M96.8627 13.3631V15.2253H89.4511V13.3631H96.8627ZM91.6113 9.93669H93.8086V23.568C93.8086 24.1887 93.8987 24.6543 94.0787 24.9646C94.2649 25.2688 94.5008 25.4736 94.7863 25.5791C95.078 25.6785 95.3853 25.7281 95.7081 25.7281C95.9502 25.7281 96.1488 25.7157 96.304 25.6909C96.4592 25.6598 96.5833 25.635 96.6764 25.6164L97.1234 27.5903C96.9744 27.6462 96.7664 27.702 96.4995 27.7579C96.2326 27.82 95.8943 27.851 95.4846 27.851C94.8639 27.851 94.2556 27.7176 93.6597 27.4507C93.07 27.1837 92.5796 26.7772 92.1885 26.2309C91.8037 25.6847 91.6113 24.9957 91.6113 24.1639V9.93669Z"
        fill="#0A090B"
      />
      <path
        className="path"
        d="M99.2546 27.6648V13.3631H101.452V27.6648H99.2546ZM100.372 10.9795C99.9437 10.9795 99.5743 10.8336 99.264 10.5419C98.9598 10.2502 98.8077 9.89944 98.8077 9.48976C98.8077 9.08007 98.9598 8.72936 99.264 8.43762C99.5743 8.14587 99.9437 8 100.372 8C100.8 8 101.167 8.14587 101.471 8.43762C101.781 8.72936 101.936 9.08007 101.936 9.48976C101.936 9.89944 101.781 10.2502 101.471 10.5419C101.167 10.8336 100.8 10.9795 100.372 10.9795Z"
        fill="#0A090B"
      />
      <path
        className="path"
        d="M114.694 16.5661L112.72 17.1248C112.596 16.7958 112.412 16.4761 112.17 16.1657C111.935 15.8492 111.612 15.5885 111.202 15.3836C110.792 15.1788 110.268 15.0763 109.628 15.0763C108.753 15.0763 108.024 15.2781 107.44 15.6816C106.863 16.0788 106.574 16.5847 106.574 17.1993C106.574 17.7455 106.773 18.1769 107.17 18.4935C107.568 18.8101 108.188 19.0739 109.033 19.2849L111.155 19.8063C112.434 20.1167 113.387 20.5916 114.014 21.2309C114.641 21.8641 114.954 22.6803 114.954 23.6797C114.954 24.4991 114.718 25.2315 114.247 25.8771C113.781 26.5227 113.129 27.0317 112.291 27.4041C111.453 27.7765 110.479 27.9628 109.368 27.9628C107.909 27.9628 106.702 27.6462 105.746 27.013C104.79 26.3799 104.185 25.455 103.93 24.2384L106.016 23.7169C106.214 24.4867 106.59 25.0639 107.142 25.4488C107.701 25.8336 108.43 26.0261 109.331 26.0261C110.355 26.0261 111.168 25.8088 111.77 25.3743C112.378 24.9336 112.682 24.406 112.682 23.7914C112.682 23.2948 112.509 22.879 112.161 22.5438C111.813 22.2024 111.28 21.9479 110.56 21.7803L108.176 21.2216C106.866 20.9112 105.904 20.4302 105.29 19.7784C104.681 19.1204 104.377 18.298 104.377 17.311C104.377 16.504 104.604 15.7902 105.057 15.1695C105.516 14.5487 106.14 14.0615 106.928 13.7076C107.723 13.3538 108.623 13.1769 109.628 13.1769C111.044 13.1769 112.155 13.4873 112.962 14.108C113.775 14.7287 114.352 15.5481 114.694 16.5661Z"
        fill="#0A090B"
      />
      <path
        className="path"
        d="M54.2477 13.3631C54.1359 12.4196 53.6828 11.6872 52.8883 11.1657C52.0937 10.6443 51.1192 10.3836 49.9646 10.3836C49.1204 10.3836 48.3818 10.5202 47.7486 10.7933C47.1217 11.0664 46.6313 11.442 46.2775 11.9199C45.9299 12.3979 45.7561 12.941 45.7561 13.5493C45.7561 14.0583 45.8771 14.496 46.1192 14.8622C46.3675 15.2222 46.684 15.5233 47.0689 15.7654C47.4538 16.0012 47.8572 16.1968 48.2793 16.352C48.7014 16.5009 49.0894 16.622 49.4432 16.7151L51.3799 17.2365C51.8765 17.3669 52.4289 17.5469 53.0372 17.7765C53.6518 18.0062 54.2384 18.3197 54.797 18.7169C55.3619 19.108 55.8274 19.6108 56.1937 20.2253C56.5599 20.8399 56.743 21.594 56.743 22.4879C56.743 23.5183 56.473 24.4494 55.933 25.2812C55.3991 26.113 54.617 26.7741 53.5866 27.2644C52.5624 27.7548 51.3178 28 49.8529 28C48.4873 28 47.3048 27.7796 46.3054 27.3389C45.3122 26.8982 44.5301 26.2837 43.959 25.4953C43.3942 24.707 43.0745 23.7914 43 22.7486H45.3836C45.4457 23.4687 45.6878 24.0646 46.1099 24.5363C46.5382 25.0019 47.0782 25.3495 47.73 25.5791C48.388 25.8026 49.0956 25.9143 49.8529 25.9143C50.7343 25.9143 51.5258 25.7716 52.2272 25.486C52.9286 25.1943 53.4842 24.7908 53.8939 24.2756C54.3035 23.7542 54.5084 23.1459 54.5084 22.4507C54.5084 21.8175 54.3315 21.3023 53.9777 20.905C53.6238 20.5078 53.1583 20.185 52.581 19.9367C52.0037 19.6884 51.3799 19.4711 50.7095 19.2849L48.3631 18.6145C46.8734 18.1862 45.694 17.5748 44.825 16.7803C43.9559 15.9857 43.5214 14.946 43.5214 13.6611C43.5214 12.5934 43.8101 11.6623 44.3873 10.8678C44.9708 10.067 45.753 9.44631 46.7337 9.00559C47.7207 8.55866 48.8225 8.33519 50.0391 8.33519C51.2682 8.33519 52.3607 8.55555 53.3166 8.99627C54.2725 9.43079 55.0298 10.0267 55.5885 10.784C56.1533 11.5413 56.4513 12.401 56.4823 13.3631H54.2477Z"
        mask="url(#path-9-outside-1_294_5058)"
        stroke="#0A090B"
      />
      <path
        className="path"
        d="M65.548 13.3631V15.2253H58.1364V13.3631H65.548ZM60.2966 9.93669H62.4939V23.568C62.4939 24.1887 62.584 24.6543 62.764 24.9646C62.9502 25.2688 63.1861 25.4736 63.4716 25.5791C63.7633 25.6785 64.0706 25.7281 64.3934 25.7281C64.6355 25.7281 64.8341 25.7157 64.9893 25.6909C65.1445 25.6598 65.2686 25.635 65.3617 25.6164L65.8087 27.5903C65.6597 27.6462 65.4517 27.702 65.1848 27.7579C64.9179 27.82 64.5796 27.851 64.1699 27.851C63.5492 27.851 62.9409 27.7176 62.345 27.4507C61.7553 27.1837 61.2649 26.7772 60.8738 26.2309C60.489 25.6847 60.2966 24.9957 60.2966 24.1639V9.93669Z"
        mask="url(#path-9-outside-1_294_5058)"
        stroke="#0A090B"
      />
      <path
        className="path"
        d="M67.9399 27.6648V13.3631H70.0628V15.5233H70.2118C70.4725 14.8156 70.9443 14.2415 71.6271 13.8007C72.3099 13.36 73.0796 13.1397 73.9362 13.1397C74.0976 13.1397 74.2993 13.1428 74.5414 13.149C74.7835 13.1552 74.9666 13.1645 75.0908 13.1769V15.4115C75.0163 15.3929 74.8456 15.365 74.5787 15.3277C74.318 15.2843 74.0417 15.2626 73.75 15.2626C73.0548 15.2626 72.434 15.4084 71.8878 15.7002C71.3478 15.9857 70.9195 16.383 70.6029 16.892C70.2925 17.3948 70.1373 17.969 70.1373 18.6145V27.6648H67.9399Z"
        mask="url(#path-9-outside-1_294_5058)"
        stroke="#0A090B"
      />
      <path
        className="path"
        d="M80.9907 28C80.0844 28 79.2619 27.8293 78.5233 27.4879C77.7846 27.1403 77.198 26.6406 76.7635 25.9888C76.329 25.3309 76.1117 24.5363 76.1117 23.6052C76.1117 22.7858 76.2731 22.1217 76.5959 21.6127C76.9187 21.0975 77.3501 20.694 77.8901 20.4022C78.4302 20.1105 79.0261 19.8932 79.6778 19.7505C80.3358 19.6015 80.9969 19.4836 81.6611 19.3966C82.5301 19.2849 83.2346 19.2011 83.7747 19.1453C84.3209 19.0832 84.7182 18.9808 84.9665 18.838C85.221 18.6952 85.3482 18.4469 85.3482 18.0931V18.0186C85.3482 17.0999 85.0968 16.3861 84.594 15.8771C84.0975 15.3681 83.3433 15.1136 82.3315 15.1136C81.2824 15.1136 80.46 15.3433 79.8641 15.8026C79.2681 16.2619 78.8492 16.7523 78.6071 17.2737L76.5214 16.5289C76.8938 15.6598 77.3904 14.9832 78.0112 14.4991C78.6381 14.0087 79.3209 13.6673 80.0596 13.4749C80.8045 13.2762 81.5369 13.1769 82.257 13.1769C82.7163 13.1769 83.2439 13.2328 83.8398 13.3445C84.442 13.45 85.0223 13.6704 85.581 14.0056C86.1459 14.3408 86.6145 14.8467 86.987 15.5233C87.3594 16.1999 87.5456 17.1061 87.5456 18.2421V27.6648H85.3482V25.7281H85.2365C85.0875 26.0385 84.8392 26.3706 84.4916 26.7244C84.144 27.0782 83.6816 27.3793 83.1043 27.6276C82.527 27.8759 81.8225 28 80.9907 28ZM81.3259 26.0261C82.1949 26.0261 82.9274 25.8554 83.5233 25.514C84.1254 25.1726 84.5785 24.7318 84.8827 24.1918C85.193 23.6518 85.3482 23.0838 85.3482 22.4879V20.4767C85.2551 20.5885 85.0503 20.6909 84.7337 20.784C84.4233 20.8709 84.0633 20.9485 83.6536 21.0168C83.2501 21.0788 82.856 21.1347 82.4711 21.1844C82.0925 21.2278 81.7852 21.2651 81.5493 21.2961C80.9783 21.3706 80.4444 21.4916 79.9478 21.6592C79.4575 21.8206 79.0602 22.0658 78.756 22.3948C78.4581 22.7176 78.3091 23.1583 78.3091 23.7169C78.3091 24.4804 78.5916 25.0577 79.1564 25.4488C79.7275 25.8336 80.4506 26.0261 81.3259 26.0261Z"
        mask="url(#path-9-outside-1_294_5058)"
        stroke="#0A090B"
      />
      <path
        className="path"
        d="M96.8627 13.3631V15.2253H89.4511V13.3631H96.8627ZM91.6113 9.93669H93.8086V23.568C93.8086 24.1887 93.8987 24.6543 94.0787 24.9646C94.2649 25.2688 94.5008 25.4736 94.7863 25.5791C95.078 25.6785 95.3853 25.7281 95.7081 25.7281C95.9502 25.7281 96.1488 25.7157 96.304 25.6909C96.4592 25.6598 96.5833 25.635 96.6764 25.6164L97.1234 27.5903C96.9744 27.6462 96.7664 27.702 96.4995 27.7579C96.2326 27.82 95.8943 27.851 95.4846 27.851C94.8639 27.851 94.2556 27.7176 93.6597 27.4507C93.07 27.1837 92.5796 26.7772 92.1885 26.2309C91.8037 25.6847 91.6113 24.9957 91.6113 24.1639V9.93669Z"
        mask="url(#path-9-outside-1_294_5058)"
        stroke="#0A090B"
      />
      <path
        className="path"
        d="M99.2546 27.6648V13.3631H101.452V27.6648H99.2546ZM100.372 10.9795C99.9437 10.9795 99.5743 10.8336 99.264 10.5419C98.9598 10.2502 98.8077 9.89944 98.8077 9.48976C98.8077 9.08007 98.9598 8.72936 99.264 8.43762C99.5743 8.14587 99.9437 8 100.372 8C100.8 8 101.167 8.14587 101.471 8.43762C101.781 8.72936 101.936 9.08007 101.936 9.48976C101.936 9.89944 101.781 10.2502 101.471 10.5419C101.167 10.8336 100.8 10.9795 100.372 10.9795Z"
        mask="url(#path-9-outside-1_294_5058)"
        stroke="#0A090B"
      />
      <path
        className="path"
        d="M114.694 16.5661L112.72 17.1248C112.596 16.7958 112.412 16.4761 112.17 16.1657C111.935 15.8492 111.612 15.5885 111.202 15.3836C110.792 15.1788 110.268 15.0763 109.628 15.0763C108.753 15.0763 108.024 15.2781 107.44 15.6816C106.863 16.0788 106.574 16.5847 106.574 17.1993C106.574 17.7455 106.773 18.1769 107.17 18.4935C107.568 18.8101 108.188 19.0739 109.033 19.2849L111.155 19.8063C112.434 20.1167 113.387 20.5916 114.014 21.2309C114.641 21.8641 114.954 22.6803 114.954 23.6797C114.954 24.4991 114.718 25.2315 114.247 25.8771C113.781 26.5227 113.129 27.0317 112.291 27.4041C111.453 27.7765 110.479 27.9628 109.368 27.9628C107.909 27.9628 106.702 27.6462 105.746 27.013C104.79 26.3799 104.185 25.455 103.93 24.2384L106.016 23.7169C106.214 24.4867 106.59 25.0639 107.142 25.4488C107.701 25.8336 108.43 26.0261 109.331 26.0261C110.355 26.0261 111.168 25.8088 111.77 25.3743C112.378 24.9336 112.682 24.406 112.682 23.7914C112.682 23.2948 112.509 22.879 112.161 22.5438C111.813 22.2024 111.28 21.9479 110.56 21.7803L108.176 21.2216C106.866 20.9112 105.904 20.4302 105.29 19.7784C104.681 19.1204 104.377 18.298 104.377 17.311C104.377 16.504 104.604 15.7902 105.057 15.1695C105.516 14.5487 106.14 14.0615 106.928 13.7076C107.723 13.3538 108.623 13.1769 109.628 13.1769C111.044 13.1769 112.155 13.4873 112.962 14.108C113.775 14.7287 114.352 15.5481 114.694 16.5661Z"
        mask="url(#path-9-outside-1_294_5058)"
        stroke="#0A090B"
      />
      <defs className="defs">
        <filter
          className="filter"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="37"
          id="filter0_d_294_5058"
          width="37"
          x="0.5"
          y="0.5"
        >
          <feFlood className="fe-flood" floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            className="fe-color-matrix"
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset className="fe-offset" dy="1" />
          <feGaussianBlur className="fe-gaussian-blur" stdDeviation="1" />
          <feComposite className="fe-composite" in2="hardAlpha" operator="out" />
          <feColorMatrix
            className="fe-color-matrix"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend className="fe-blend" in2="BackgroundImageFix" mode="normal" result="effect1_dropShadow_294_5058" />
          <feBlend
            className="fe-blend"
            in="SourceGraphic"
            in2="effect1_dropShadow_294_5058"
            mode="normal"
            result="shape"
          />
        </filter>
        <filter
          className="filter"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
          height="32"
          id="filter1_i_294_5058"
          width="32"
          x="3"
          y="2"
        >
          <feFlood className="fe-flood" floodOpacity="0" result="BackgroundImageFix" />
          <feBlend className="fe-blend" in="SourceGraphic" in2="BackgroundImageFix" mode="normal" result="shape" />
          <feColorMatrix
            className="fe-color-matrix"
            in="SourceAlpha"
            result="hardAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset className="fe-offset" />
          <feGaussianBlur className="fe-gaussian-blur" stdDeviation="0.75" />
          <feComposite className="fe-composite" in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix
            className="fe-color-matrix"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"
          />
          <feBlend className="fe-blend" in2="shape" mode="normal" result="effect1_innerShadow_294_5058" />
        </filter>
        <linearGradient
          className="linear-gradient"
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_294_5058"
          x1="19"
          x2="19"
          y1="1.06369e-07"
          y2="47"
        >
          <stop className="stop" stopColor="white" />
          <stop className="stop" offset="1" stopColor="#EAE8F0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
