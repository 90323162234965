import React, { useState } from 'react'
import './Form.css'
import { Link, useNavigate } from 'react-router-dom'
import { KokorokoLogo } from '../../icons/KokorokoLogo';
import  PhoneInput  from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Slide } from 'react-awesome-reveal';


export const Form = () => {
    const navigate = useNavigate();
    const [loading, setLoading ] = useState(false);
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phone: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevDate) => ({
            ...prevDate,
            [name]: value
        }));
    };

    const handlePhoneChange = (phone) => {
        setFormData((prevData) => ({
            ...prevData,
            phone: phone
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { fullName, email, phone } = formData;
        setLoading(true);
        
        try {
            await fetch('https://hook.eu2.make.com/n28nau8ylxch6tp288wo1vjvz3jt5bdh', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                
                body: JSON.stringify({ fullName, email, phone }),
            });

            alert('Form submitted successfully!');
            navigate('/');
            
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    return (
        <div className='form-page'>
            <div className='form-container'>
                <div className='form-text'>
                    <h1 className='form-title'>Sign Up, Stay Updated</h1>
                    <p className='form-subtitle'>
                        We’ll notify you when Kokorko is live! Whether you’re ready to grow your business or discover amazing products, be the first to start your journey with us.
                    </p>
                </div>
                <Slide direction='right' duration={1000} className='form-inputs'>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className='input-wrapper'>
                            <label htmlFor="fullName" className='input-label'>Full Name:</label>
                            <input
                                className='input-field'
                                type="text"
                                id="fullName"
                                name="fullName"
                                placeholder='Enter your name'
                                value={formData.fullName}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className='input-wrapper'>
                            <label htmlFor="email" className='input-label'>Email:</label>
                            <input
                                className='input-field'
                                type="email"
                                id="email"
                                name="email"
                                placeholder='Enter your email'
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className='input-wrapper'>
                            <label htmlFor="phone" className='input-label'>Phone Number:</label>
                            <PhoneInput
                                className='input-field'
                                id="phone"
                                name="phone"
                                country="GH"  //Set default country code here
                                value={formData.phone}
                                placeholder='Enter your phone number'
                                onChange={handlePhoneChange}
                            />
                        </div>

                        <button className='submit-button' type="submit" disabled={loading}>
                            { loading ? 'Submitting...' : 'Get Notified'}
                        </button>
                    </form>
                </Slide>
            </div>
        </div>
    );
};
