import React from "react";
import { FooterSection } from "../../components/FooterSection";
import { KokorokoLogo } from "../../icons/KokorokoLogo";
import { ShoppingCart } from "../../icons/ShoppingCart";
import "./style.css";
import { Link } from "react-router-dom";
import { Slide, Fade } from "react-awesome-reveal";

export const HeaderSection = () => {
  return (
    <div className="header-section">
      <div className="header-section-container">
        <Link to={'/'}>
          <KokorokoLogo className="kokoroko-logo" />
        </Link>
        <div className="header-section-buttons">
          <a href={'/'} className="text-wrapper-2">About Us</a>
          <Link to={'/form'} className="buttons-primary-2">
            <div className="button-text-3">Get Notified</div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export const DesktopComingSoon = () => {
  return (
    <div className="desktop-coming-soon">
      <Slide direction="left" duration={2000}>
        <div className="div-2">
          {/* <div className="frame-2">
            <KokorokoLogo className="kokoroko-logo" />
            <div className="frame-3">
              <a href={'#about-us'} className="text-wrapper-2">About Us</a>
              <Link to={'/form'} className="buttons-primary-2">
                <div className="button-text-3">Get Notified</div>
              </Link>
            </div>
          </div> */}
          <div className="frame-4">
            <div className="frame-5">
              <div className="frame-6">
                <img className="icon-park-outline" alt="Icon park outline" src="/img/icon-park-outline-loading-one.svg" />
                <div className="frame-7">
                  <div className="text-wrapper-3">Coming Soon</div>
                </div>
              </div>
              <Fade cascade damping={0.02} duration={2000} triggerOnce={false} className="text-wrapper-4">
                Exciting New Shopping Experience!
              </Fade>
            </div>
            <p className="text-wrapper-5">
              Discover fashion, electronics, home essentials, and so much more. Stay tuned!
            </p>
            <Link to={'/form'} className="button-text-wrapper">
              <div className="button-text-2">Get Notified</div>
            </Link>
          </div>
          <div className="overlap">
            <div className="group">
              <div className="overlap-group">
                {/* <div className="ellipse" /> */}
                <div className="ellipse-2" />
                <div className="ellipse" />
              </div>
            </div>
            <div className="mock-up-wrapper">
              <div className="mock-up">
                  <img
                    className="studio-display-front"
                    alt="Studio display front"
                    src="/img/studio-display-front-view-mockup-label.png"
                  />
              </div>
            </div> 
            <img className="img" alt="Group" src="/img/group-2.png" />
          </div>
          <div className="frame-8">
            <div className="frame-9">
              <div className="frame-10">
                <div className="frame-11">
                  <img className="vector" alt="Vector" src="/img/vector-1.svg" />
                  <p className="text-wrapper-6">VerifiBuy: Your Money, Protected Until You’re Happy</p>
                </div>
                <p className="text-wrapper-7">
                  We understand that trust is key in online shopping, and Kokorko makes it easier than ever to shop with
                  confidence. With VerifiBuy, your payments are protected through an Escrow system—but don’t worry, we’ve
                  made it simple
                </p>
              </div>
              <div className="frame-12">
                <div className="frame-wrapper">
                  <div className="frame-13">
                    <div className="text-wrapper-8">What is Verifibuy?</div>
                    <p className="text-wrapper-9">
                      It’s a secure payment service that holds your money until you receive your order. Once you confirm
                      that everything’s as expected, the payment is released to the seller. This ensures you never pay for
                      something you haven’t received, giving both buyers and sellers peace of mind
                    </p>
                  </div>
                </div>
                <div className="frame-wrapper">
                  <div className="frame-13">
                    <div className="text-wrapper-8">How it works</div>
                    <p className="text-wrapper-9">
                      Place an order and make a payment.
                      <br />
                      <br />
                      The money is held in VerifiBuy Escrow.
                      <br />
                      <br />
                      When your order is delivered and you&#39;re satisfied, the payment is released to the seller.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-14">
              <div className="frame-10">
                <div className="frame-3">
                  <img className="group-2" alt="Group" src="/img/group.png" />
                  <p className="text-wrapper-10">For Sellers: Grow Your Business with Ease</p>
                </div>
                <p className="text-wrapper-11">
                  Kokorko isn’t just a platform—it’s a powerful tool that helps you run and grow your business. Here’s
                  what you can expect as a merchant:
                </p>
              </div>
              <div className="frame-17">
                <div className="frame-15">
                  <div className="frame-13">
                    <p className="text-wrapper-8">Get Paid Safely with VerifiBuy</p>
                    <p className="text-wrapper-9">
                      No more worrying about getting paid! With VerifiBuy, payments are securely held until your buyer
                      confirms delivery. Once the buyer is satisfied, you get paid instantly—giving you peace of mind and
                      confidence in every transaction.
                    </p>
                  </div>
                </div>
                <div className="frame-15">
                  <div className="frame-13">
                    <div className="text-wrapper-8">Expand Your Reach</div>
                    <p className="text-wrapper-9">
                      Connect your social media channels to Kokorko and turn your followers into customers. Our platform
                      helps you reach new buyers by showcasing your products in a modern, user-friendly online store.
                    </p>
                  </div>
                </div>
                <div className="frame-15">
                  <div className="frame-13">
                    <div className="text-wrapper-8">Manage Your Business, Effortlessly</div>
                    <p className="text-wrapper-9">
                      From adding products to tracking orders and handling payments, Kokorko gives you all the tools you
                      need to run your business smoothly. Our easy-to-use dashboard lets you stay on top of everything
                      with minimal effort.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-14">
              <div className="frame-16">
                <div className="frame-3">
                  <ShoppingCart className="shopping-cart" />
                  <p className="text-wrapper-6">For Buyers: Shop Confidently, Every Time</p>
                </div>
                <p className="text-wrapper-11">
                  Kokorko is designed to make your shopping experience easy, secure, and worry-free. Here’s how we’re
                  looking out for you:
                </p>
              </div>
              <div className="frame-17">
                <div className="frame-18">
                  <div className="frame-13">
                    <div className="text-wrapper-8">Secure Transactions</div>
                    <p className="text-wrapper-9">
                      With VerifiBuy, you never have to worry about losing your money. Your payment is only released to
                      the seller when you confirm that your order has been delivered and is exactly what you expected.
                    </p>
                  </div>
                </div>
                <div className="frame-18">
                  <div className="frame-13">
                    <div className="text-wrapper-8">Discover New Sellers</div>
                    <p className="text-wrapper-9">
                      Browse through a variety of merchants offering unique products across different categories.
                      Kokorko’s social integration lets you see more about a seller’s brand and their activity online.
                    </p>
                  </div>
                </div>
                <div className="frame-18">
                  <div className="frame-13">
                    <div className="text-wrapper-8">Seamless Shopping Experience</div>
                    <p className="text-wrapper-9">
                      Our platform is designed with you in mind—whether you’re shopping on your phone, tablet, or
                      computer, Kokorko makes it easy to explore products, make purchases, and track your orders.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Slide>
        <div className="about-us" id="about-us">
            <div className="frame-19">
              <div className="text-wrapper-12">About Us</div>
              <p className="at-kokorko-we">
              At VerifiBuy, we understand the challenges entrepreneurs and small businesses face when it comes to selling online. 
              That's why we created Kokorko—your all-in-one solution for easier, faster, and more secure online selling. 
              Struggling to manage sales across multiple social platforms? Kokorko seamlessly integrates with your social media, helping you reach more customers in no time. 
              Worried about secure payments? With VerifiBuy’s trusted payment infrastructure, you can focus on growing your business without the hassle. 
              Whether you're just starting out or aiming to scale, Kokorko has the tools you need to thrive. Let's solve the online selling challenge together—because when your business grows, so does ours.
              </p>
          </div>
          <div className="footer-bottom-bar">
            <FooterSection
              className="footer-elements"
              copyrightClassName="footer-section-elements"
              text1="© 2024 Kokorko - All rights reserved"
              type="copyright"
            />
            <FooterSection
              className="footer-elements"
              divClassName="footer-section-elements"
              subheadTitleClassName="footer-section-elements"
              subheadTitleClassNameOverride="footer-section-elements"
              type="footer-section"
            />
          </div>
        </div>
    </div>
  );
};
