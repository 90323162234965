import React from 'react'
import './Terms.css'
import { FooterSection } from '../FooterSection'

export const Terms = () => {
    return (
        <div className='terms-condition'>
            <div class="terms-container">
                <h1>Terms and Conditions</h1>
                <div className='terms-content'>
                    <h2>1. Introduction</h2>
                    <p>Welcome to Kokorko! Kokorko is an all-in-one e-commerce platform designed to simplify online selling for entrepreneurs and small businesses. It integrates seamlessly with your social media, allowing you to easily manage sales and reach more customers. With VerifiBuy's trusted payment infrastructure, Kokorko ensures secure transactions, so you can focus on growing your business without worrying about payments. Whether you're just starting or looking to scale, Kokorko provides the tools you need for faster, easier, and more secure online selling.</p>
                </div>
                <div className='terms-content'>
                    <h2>2. Acceptance of Terms</h2>
                    <p>By registering for, accessing, or using Kokorko, you accept and agree to be bound by these Terms and Conditions and our Privacy Policy. If you do not agree with any of the terms, please refrain from using our platform.</p>
                </div>
                <div className='terms-content'>
                    <h2>3. Eligibility</h2>
                    <p>To use Kokorko, you must be at least 18 years old or have parental or legal guardian consent. You are responsible for providing accurate, up-to-date information during registration.</p>
                </div>
                <div className='terms-content'>
                    <h2>4. User Accounts</h2>
                    <p>You are responsible for maintaining the confidentiality of your account and password and for restricting access to your device. Kokorko is not responsible for any activity that occurs under your account due to your failure to secure your account credentials.</p>
                </div>
                <div className='terms-content'>
                    <h2>5. Use of Services</h2>
                    <p>You agree to use Kokorko for lawful purposes only. You will not engage in fraudulent activities, violate intellectual property rights, or exploit the platform in ways that harm Kokorko or its users.</p>
                </div>
                <div className='terms-content'>
                    <h2>6. Payments and Fees</h2>
                    <p>All transactions made through Kokorko are subject to our secure payment infrastructure. VerifiBuy ensures the security of your transactions, but we are not responsible for any losses arising from third-party payment systems you may use in conjunction with our platform.</p>
                </div>
                <div className='terms-content'>
                    <h2>7. Content Ownership and License</h2>
                    <p>You retain ownership of any content, such as photos or descriptions, that you upload to Kokorko. However, by uploading content, you grant VerifiBuy a worldwide, non-exclusive, royalty-free license to use, reproduce, and distribute your content for the purpose of operating the platform.</p>
                </div>
                <div className='terms-content list'>
                    <h2>8. Prohibited Activities</h2>
                    <p>You agree not to:</p>
                    <ul>
                        <li>Misuse the platform by transmitting harmful or illegal content.</li>
                        <li>Use the platform to engage in fraudulent or unethical activities.</li>
                        <li>Exploit security vulnerabilities in Kokorko.</li>
                    </ul>
                </div>
                <div className='terms-content'>
                    <h2>9. Termination of Use</h2>
                    <p>VerifiBuy reserves the right to suspend or terminate your account at any time, with or without cause, including for violating these Terms and Conditions.</p>
                </div>
                <div className='terms-content'>
                    <h2>10. Disclaimer</h2>
                    <p>Kokorko is provided on an "as-is" and "as available" basis without warranties of any kind. We do not guarantee that the service will be uninterrupted, secure, or error-free.</p>
                </div>
                <div className='terms-content'>
                    <h2>11. Limitation of Liability</h2>
                    <p>To the maximum extent permitted by law, VerifiBuy is not liable for any indirect, incidental, special, or consequential damages arising from your use of Kokorko.</p>
                </div>
                <div className='terms-content'>
                    <h2>12. Governing Law</h2>
                    <p>These Terms and Conditions are governed by and construed in accordance with the laws of [your jurisdiction].</p>
                </div>
                <div className='terms-content'>
                    <h2>13. Amendments</h2>
                    <p>We reserve the right to amend these Terms and Conditions at any time. Continued use of Kokorko after changes are made constitutes your acceptance of the revised terms.</p>
                </div>
                <div className='terms-content'>
                    <h2>14. Contact Us</h2>
                    <p>If you have any questions or concerns about these Terms and Conditions, please contact us at <a href='mailto:hello@verifibuy.com'>hello@verifibuy.com</a>.</p>
                </div>
            </div>
            <div className="footer-container">
                <div className='footer-elements-container'>
                    <FooterSection
                    className="footer-elements"
                    copyrightClassName="footer-section-elements"
                    text1="© 2024 Kokorko - All rights reserved"
                    type="copyright"
                    />
                    <FooterSection
                    className="footer-elements"
                    divClassName="footer-section-elements"
                    subheadTitleClassName="footer-section-elements"
                    subheadTitleClassNameOverride="footer-section-elements"
                    type="footer-section"
                    />
                </div>
            </div>
        </div>
    )
}
