/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const MiscIconsSocial1 = ({ className }) => {
  return (
    <svg
      className={`misc-icons-social-1 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M5.03168 14.5005C11.0694 14.5005 14.3718 9.49822 14.3718 5.1603C14.3718 5.01822 14.3718 4.87678 14.3622 4.73598C15.0047 4.27128 15.5593 3.6959 16 3.03678C15.4009 3.30238 14.7654 3.47648 14.1146 3.55326C14.7999 3.14305 15.3128 2.49778 15.5578 1.73758C14.9134 2.11998 14.2084 2.38946 13.4733 2.53438C12.9783 2.0081 12.3237 1.65961 11.6108 1.54283C10.8978 1.42606 10.1663 1.54752 9.52931 1.88841C8.89234 2.22931 8.38548 2.77063 8.08716 3.42861C7.78884 4.08659 7.71569 4.82455 7.87904 5.5283C6.57393 5.46283 5.29717 5.12365 4.13164 4.53278C2.9661 3.94191 1.93784 3.11255 1.1136 2.09854C0.693819 2.8212 0.565248 3.67669 0.754066 4.49082C0.942885 5.30495 1.43489 6.01651 2.12992 6.48062C1.60749 6.46531 1.09643 6.32438 0.64 6.06974V6.11134C0.640207 6.86924 0.902567 7.60374 1.38258 8.19025C1.86259 8.77676 2.53071 9.17919 3.2736 9.32926C2.79032 9.46108 2.28325 9.48035 1.79136 9.38558C2.00121 10.0378 2.40962 10.6081 2.95949 11.0169C3.50937 11.4256 4.17322 11.6523 4.85824 11.6653C4.17763 12.2002 3.39821 12.5958 2.56458 12.8293C1.73096 13.0627 0.859476 13.1296 0 13.0259C1.50122 13.9892 3.24795 14.5002 5.03168 14.4979"
        fill="#7F7D83"
      />
    </svg>
  );
};
