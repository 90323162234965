/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const MiscIconsSocial4 = ({ className }) => {
  return (
    <svg
      className={`misc-icons-social-4 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="g" clipPath="url(#clip0_294_5054)">
        <path
          className="path"
          d="M15.6813 4.12364C15.5909 3.78325 15.4126 3.47259 15.1643 3.22274C14.9161 2.9729 14.6066 2.79264 14.2668 2.7C13.0159 2.36364 8.01587 2.36364 8.01587 2.36364C8.01587 2.36364 3.01587 2.36364 1.76496 2.7C1.42516 2.79264 1.11564 2.9729 0.867394 3.22274C0.619147 3.47259 0.440873 3.78325 0.350415 4.12364C0.0158692 5.38 0.0158691 8 0.0158691 8C0.0158691 8 0.0158692 10.62 0.350415 11.8764C0.440873 12.2168 0.619147 12.5274 0.867394 12.7773C1.11564 13.0271 1.42516 13.2074 1.76496 13.3C3.01587 13.6364 8.01587 13.6364 8.01587 13.6364C8.01587 13.6364 13.0159 13.6364 14.2668 13.3C14.6066 13.2074 14.9161 13.0271 15.1643 12.7773C15.4126 12.5274 15.5909 12.2168 15.6813 11.8764C16.0159 10.62 16.0159 8 16.0159 8C16.0159 8 16.0159 5.38 15.6813 4.12364Z"
          fill="#7F7D83"
        />
        <path className="path" d="M6.37952 10.3791V5.62093L10.5613 8.00002L6.37952 10.3791Z" fill="white" />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_294_5054">
          <rect className="rect" fill="white" height="16" width="16" />
        </clipPath>
      </defs>
    </svg>
  );
};
