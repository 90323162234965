import React from 'react'
import './Main.css'
import { DesktopComingSoon } from '../DesktopComingSoon'
import { FooterSection } from '../../components/FooterSection'
import { HeaderSection } from '../DesktopComingSoon/DesktopComingSoon'
import Headroom from 'react-headroom'

const Main = () => {
    return (
        <div>
            <DesktopComingSoon />
        </div>
    )
}

export default Main
