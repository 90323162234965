/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const ShoppingCart = ({ className }) => {
  return (
    <svg
      className={`shopping-cart ${className}`}
      fill="none"
      height="76"
      viewBox="0 0 76 76"
      width="76"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        d="M3.1665 3.16736H15.8332L24.3198 45.569C24.6094 47.0269 25.4025 48.3366 26.5604 49.2686C27.7183 50.2007 29.167 50.6958 30.6532 50.6674H61.4332C62.9193 50.6958 64.3681 50.2007 65.526 49.2686C66.6838 48.3366 67.4769 47.0269 67.7665 45.569L72.8332 19.0007H18.9998M31.6665 66.5007C31.6665 68.2496 30.2487 69.6674 28.4998 69.6674C26.7509 69.6674 25.3332 68.2496 25.3332 66.5007C25.3332 64.7518 26.7509 63.334 28.4998 63.334C30.2487 63.334 31.6665 64.7518 31.6665 66.5007ZM66.4998 66.5007C66.4998 68.2496 65.0821 69.6674 63.3332 69.6674C61.5843 69.6674 60.1665 68.2496 60.1665 66.5007C60.1665 64.7518 61.5843 63.334 63.3332 63.334C65.0821 63.334 66.4998 64.7518 66.4998 66.5007Z"
        stroke="#5275B7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.5"
      />
    </svg>
  );
};
