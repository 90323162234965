import React from 'react'
import './Privacy.css'
import { FooterSection } from '../FooterSection'

export const Privacy = () => {
    return (
        <div className='privacy-policy'>
            <div className='privacy-policy-container'>
                <h1>Privacy Policy</h1>

                <div className='privacy-policy-content'>
                    <h2>1. Introduction</h2>
                    <p>At Kokorko, protecting your personal information is a priority. This Privacy Policy explains how we collect, use, and protect your data when you use our platform.</p>
                </div>

                <div className='privacy-policy-content'>
                    <h2>2. Information We Collect</h2>
                    <ul>
                        <li><strong>Personal Information:</strong> When you create an account, we collect personal details such as your name, email address, phone number, and payment information.</li>
                        <li><strong>Transaction Information:</strong> We record information about the transactions you make through the platform, including product details, payment amounts, and dates.</li>
                        <li><strong>Technical Information:</strong> We automatically collect information about the devices you use to access Kokorko, including your IP address, browser type, and usage patterns.</li>
                    </ul>
                </div>

                <div className='privacy-policy-content'>
                    <h2>3. How We Use Your Information</h2>
                    <ul>
                        <li>Facilitate transactions on Kokorko.</li>
                        <li>Improve and customize your experience on the platform.</li>
                        <li>Communicate with you about your account, transactions, and platform updates.</li>
                        <li>Ensure secure and fraud-free transactions.</li>
                    </ul>
                </div>

                <div className='privacy-policy-content'>
                    <h2>4. Data Sharing</h2>
                    <p>We do not sell or rent your personal data to third parties. However, we may share your data with trusted service providers who help us operate our platform as well as when required by law or for fraud prevention.</p>
                </div>

                <div className='privacy-policy-content'>
                    <h2>5. Cookies and Tracking Technologies</h2>
                    <p>We use cookies and similar tracking technologies to enhance your experience on Kokorko. These cookies help us remember your preferences and track your usage patterns. You can disable cookies in your browser settings; however doing so may limit your ability to use certain features of the platform.</p>
                </div>

                <div className='privacy-policy-content'>
                    <h2>6. Data Security</h2>
                    <p>We implement various security measures to protect your personal data including encryption and secure payment gateways. However no system is 100% secure; we cannot guarantee the absolute security of your information.</p>
                </div>

                <div className='privacy-policy-content'>
                    <h2>7. Data Retention</h2>
                    <p>We retain your personal data for as long as necessary to provide our services comply with legal obligations resolve disputes and enforce agreements.</p>
                </div>

                <div className='privacy-policy-content'>
                    <h2>8. Your Rights</h2>
                    <p>You have the right to access update or delete your personal information at any time. If you wish to exercise any of these rights please contact us at [support email].</p>
                </div>

                <div className='privacy-policy-content'>
                    <h2>9. Children's Privacy</h2>
                    <p>Kokorko is not intended for use by children under 13 years old; we do not knowingly collect personal information from children under 13 without parental consent.</p>
                </div>

                <div className='privacy-policy-content'>
                    <h2>10. Third-Party Links</h2>
                    <p>This platform may contain links to third-party websites; we are not responsible for their privacy practices; we encourage you to review their privacy policies.</p>
                </div>

                <div className='privacy-policy-content'>
                    <h2>11. Changes to This Policy</h2>
                    <p>This Privacy Policy may be updated from time to time; changes will be posted on this page; continued use of Kokorko constitutes acceptance of revised policy.</p>
                </div>

                <div className='privacy-policy-content'>
                    <h2>12. Contact Us</h2>
                    <p>If you have any questions about this Privacy Policy please contact us at hello@verifibuy.com.</p>
                </div>
            </div>
            <div className="footer-container">
                <div className='footer-elements-container'>
                    <FooterSection
                    className="footer-elements"
                    copyrightClassName="footer-section-elements"
                    text1="© 2024 Kokorko - All rights reserved"
                    type="copyright"
                    />
                    <FooterSection
                    className="footer-elements"
                    divClassName="footer-section-elements"
                    subheadTitleClassName="footer-section-elements"
                    subheadTitleClassNameOverride="footer-section-elements"
                    type="footer-section"
                    />
                </div>
            </div>
        </div>
    )
}


