import React from 'react';
// import { DesktopComingSoon } from "./screens/DesktopComingSoon";
import { Form } from "./screens/Form/Form";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { ScrollToHash } from './components/ScrollToHash/ScrollToHash';
import { Terms } from './components/Terms/Terms';
import { Privacy } from './components/Privacy/Privacy';
import Main from './screens/Main/Main';
import { HeaderSection } from './screens/DesktopComingSoon/DesktopComingSoon';
import Headroom from 'react-headroom';
import { FooterSection } from './components/FooterSection';

export const App = () => {
    return (
        <Router>
            <Headroom>
                <HeaderSection />
            </Headroom>
            <Routes>
                <Route index="/" element={<Main />} />
                <Route path="/form" element={<Form />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path='*' element={'Page not found'} />
            </Routes>
        </Router>
    )
}
