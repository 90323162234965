/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

export const MiscIconsSocial2 = ({ className }) => {
  return (
    <svg
      className={`misc-icons-social-2 ${className}`}
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className="path"
        clipRule="evenodd"
        d="M8 0C3.58352 0 0 3.58352 0 8C0 12.4165 3.58352 16 8 16C12.4078 16 16 12.4165 16 8C16 3.58352 12.4078 0 8 0ZM13.2842 3.68763C14.2386 4.85033 14.8113 6.33405 14.8287 7.93925C14.6031 7.8959 12.347 7.436 10.0737 7.72235C10.0217 7.60955 9.9783 7.48805 9.92625 7.3666C9.7874 7.0369 9.63125 6.6985 9.47505 6.37745C11.9913 5.3536 13.1367 3.87853 13.2842 3.68763ZM8 1.18005C9.73535 1.18005 11.3232 1.83081 12.5293 2.89805C12.4078 3.07159 11.3752 4.45119 8.94575 5.36225C7.82645 3.30585 6.5857 1.62256 6.3948 1.36225C6.9067 1.24078 7.4447 1.18005 8 1.18005ZM5.0933 1.82212C5.2755 2.06507 6.49025 3.75705 7.6269 5.77005C4.43384 6.6204 1.61388 6.60305 1.31019 6.60305C1.75271 4.4859 3.18438 2.72451 5.0933 1.82212ZM1.16269 8.0087C1.16269 7.93925 1.16269 7.86985 1.16269 7.80045C1.4577 7.8091 4.77224 7.8525 8.1822 6.82865C8.3818 7.2104 8.564 7.60085 8.73755 7.9913C8.65075 8.01735 8.5553 8.0434 8.46855 8.0694C4.94577 9.20605 3.07158 12.3124 2.9154 12.5726C1.8308 11.3666 1.16269 9.7614 1.16269 8.0087ZM8 14.8373C6.4208 14.8373 4.96312 14.2993 3.80911 13.3969C3.93058 13.1453 5.31885 10.4729 9.17135 9.128C9.1887 9.1193 9.1974 9.1193 9.21475 9.11065C10.1779 11.6009 10.5683 13.692 10.6725 14.2907C9.84815 14.6464 8.94575 14.8373 8 14.8373ZM11.8091 13.6659C11.7397 13.2494 11.3752 11.2538 10.4816 8.79825C12.6248 8.45985 14.4989 9.0152 14.7332 9.0933C14.4382 10.9935 13.3449 12.6334 11.8091 13.6659Z"
        fill="#7F7D83"
        fillRule="evenodd"
      />
    </svg>
  );
};
